const configs = {
	local: {
		apiUrl: 'http://192.168.100.112:9080',
		apiImagesUrl: 'http://192.168.100.112:9080/image',
		fbAppId: '1436570349884735',
		googleClientId: '941778011187-p9bpmk42pu3mukspklu4s73gmgqa7f42.apps.googleusercontent.com',
		isTest: true,
	},
	localSSL: {
		apiUrl: 'https://local-qr.ebar.mk:9083',
		apiImagesUrl: 'https://local-qr.ebar.mk:9083/image',
		fbAppId: '1436570349884735',
		googleClientId: '941778011187-p9bpmk42pu3mukspklu4s73gmgqa7f42.apps.googleusercontent.com',
		isTest: true,
	},
	dev: {
		apiUrl: 'https://dev-api.ebar.mk',
		apiImagesUrl: 'https://dev-images.ebar.mk',
		fbAppId: '1436570349884735',
		googleClientId: '941778011187-p9bpmk42pu3mukspklu4s73gmgqa7f42.apps.googleusercontent.com',
		isTest: true,
	},
	test: {
		apiUrl: 'https://test-api.ebar.mk',
		apiImagesUrl: 'https://test-images.ebar.mk',
		fbAppId: '1436570349884735',
		googleClientId: '941778011187-p9bpmk42pu3mukspklu4s73gmgqa7f42.apps.googleusercontent.com',
		isTest: true,
	},
	production: {
		apiUrl: 'https://api.e-bar.mk',
		apiImagesUrl: 'https://images.e-bar.mk',
		fbAppId: '1436570349884735',
		googleClientId: '941778011187-p9bpmk42pu3mukspklu4s73gmgqa7f42.apps.googleusercontent.com',
		isTest: false,

	},
};

const config = configs[process.env.REACT_APP_STAGE];

export default {
	// Add common config values here
	MAX_ATTACHMENT_SIZE: 5000000,
	...config,
};
